import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Link } from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';

const CallToAction = ({ data, className, customMargin }) => {
  const { caption, reference, size, color_scheme } = data || {};
  const { subtitle, title } = caption || {};
  const { reference_path, reference_text, reference_type } = reference || {};

  const width = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(width > 1024 ? true : false);

  const darkTheme = color_scheme === 'dark';

  const btnColor = darkTheme ? 'btn_transparent-green' : 'btn_dark-purple';

  const isMedium = size === 'medium';

  if (width) {
    let newWidth = width > 1024 ? true : false;
    if (isDesktop !== newWidth) {
      setIsDesktop(newWidth);
    }
  }

  const callToActionData = [
    {
      type: reference_type,
      href: reference_path,
      title: reference_text,
    },
  ];

  return (
    <Section
      className={cn(
        'cta',
        [`cta_${size}`],
        [`cta_${color_scheme}`],
        [className],
        customMargin && 'cta--custom-margin'
      )}
    >
      <Container>
        <Row className="cta__container">
          <Column col="12" lg={isMedium ? '6' : '9'}>
            <Text
              className={cn('cta__title', [
                { cta__title_none: reference_type === 'link' && !isDesktop },
                isMedium && 'cta__title--medium',
                'headline-1',
              ])}
              color={cn({
                'transparent-green': darkTheme,
                'dark-purple': !darkTheme,
              })}
            >
              {title}
            </Text>
            <Text
              className={cn('cta__subtitle body-sm_thin')}
              color={cn({ 'transparent-green': darkTheme })}
            >
              {subtitle}
            </Text>

            {callToActionData.map(({ type, title, href }, id) => {
              switch (type) {
                case 'link':
                  return (
                    <Link
                      href={href}
                      className="cta__link"
                      isRouter={false}
                      key={id}
                    >
                      <Text
                        color={cn({ 'transparent-green': darkTheme })}
                        className={cn('headline-3 cta__link-text')}
                      >
                        {title}
                      </Text>
                    </Link>
                  );

                case 'button':
                  return (
                    <Link
                      href={href}
                      className={cn('cta__btn btn', [btnColor])}
                      key={id}
                    >
                      <Text
                        tag="span"
                        className="subheader"
                        color={cn({ 'transparent-green': darkTheme })}
                      >
                        {title}
                      </Text>
                    </Link>
                  );

                default:
                  return null;
              }
            })}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Link.defaultProps = {
  className: '',
};

Link.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default CallToAction;
